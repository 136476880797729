"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAddToAuction = exports.useAuctionApprove = exports.useAuctionFinalize = exports.useCancelAuction = exports.useBuyNow = exports.useBidding = void 0;
const _abi_1 = require("@abi");
const voucher_service_1 = require("@app/services/voucher.service");
const approve_hooks_1 = require("@hooks/contracts/approve.hooks");
const contract_hooks_1 = require("@hooks/contracts/contract.hooks");
const translation_hooks_1 = require("@hooks/translation.hooks");
const object_1 = require("@utils/object");
const number_1 = require("@utils/object/number");
const core_1 = require("@web3-react/core");
const react_1 = require("react");
const voucherService = new voucher_service_1.VoucherService();
const useBidding = (tokenId, paymentToken, address) => {
    const translator = (0, translation_hooks_1.useComponentTranslation)("txMessages");
    const { account } = (0, core_1.useWeb3React)();
    const commandExecutor = (0, contract_hooks_1.useDefaultContractWithAddressCallback)("MCSingleAuction", address, "bidPremium");
    return (0, react_1.useCallback)((bid) => __awaiter(void 0, void 0, void 0, function* () {
        if (!paymentToken) {
            throw new Error("Payment token was not found");
        }
        if (!account) {
            return;
        }
        const summary = translator("bidPremium", {
            tokenId,
            bid: (0, number_1.formatAndNormalizeToken)(paymentToken, bid, paymentToken.decimals),
        });
        const bidVoucher = yield voucherService.getAuctionBidVoucher(tokenId, account, bid.toFixed(0));
        if (!bidVoucher) {
            throw new Error("Bid voucher is empty");
        }
        const promiseCallback = yield commandExecutor(summary, [bidVoucher], tokenId);
        return promiseCallback();
    }), [commandExecutor, tokenId, account, address]);
};
exports.useBidding = useBidding;
const useBuyNow = (tokenId, address) => {
    const commandExecutor = (0, contract_hooks_1.useDefaultContractWithAddressCallback)("MCSingleAuction", address, "buyNowSingle");
    const translator = (0, translation_hooks_1.useComponentTranslation)("txMessages");
    const summary = translator("buyNowSingle", {
        tokenId,
    });
    const { account, chainId } = (0, core_1.useWeb3React)();
    return (0, react_1.useCallback)(() => __awaiter(void 0, void 0, void 0, function* () {
        if (!account) {
            return;
        }
        const voucher = yield voucherService.getBuyNowVoucher(account, tokenId);
        const promiseCallback = yield commandExecutor(summary, [_abi_1.ContractManager.getCurrentContractAddress("MCNFT", chainId), tokenId, voucher], tokenId);
        return promiseCallback();
    }), [commandExecutor, tokenId, account, address]);
};
exports.useBuyNow = useBuyNow;
const useCancelAuction = (tokenId, address) => {
    const { account, chainId } = (0, core_1.useWeb3React)();
    const translator = (0, translation_hooks_1.useComponentTranslation)("txMessages");
    const commandExecutor = (0, contract_hooks_1.useDefaultContractWithAddressCallback)("MCSingleAuction", address, "cancelAuction");
    const summary = translator("cancelAuction", {
        tokenId,
    });
    return (0, react_1.useCallback)(() => __awaiter(void 0, void 0, void 0, function* () {
        const promiseCallback = yield commandExecutor(summary, [_abi_1.ContractManager.getCurrentContractAddress("MCNFT", chainId), tokenId], tokenId);
        return promiseCallback();
    }), [commandExecutor, tokenId, account, address]);
};
exports.useCancelAuction = useCancelAuction;
const useAuctionFinalize = (tokenId, address) => {
    const { account, chainId } = (0, core_1.useWeb3React)();
    const translator = (0, translation_hooks_1.useComponentTranslation)("txMessages");
    const commandExecutor = (0, contract_hooks_1.useDefaultContractWithAddressCallback)("MCSingleAuction", address, "finalizeSingleAuction");
    const summary = translator("finalizeSingleAuction", {
        tokenId,
    });
    return (0, react_1.useCallback)(() => __awaiter(void 0, void 0, void 0, function* () {
        if (!account) {
            return;
        }
        const voucher = yield voucherService.getFinalizeAuctionVoucher(account, tokenId);
        const promiseCallback = yield commandExecutor(summary, [_abi_1.ContractManager.getCurrentContractAddress("MCNFT", chainId), tokenId, voucher], tokenId);
        return promiseCallback();
    }), [commandExecutor, tokenId, account, address]);
};
exports.useAuctionFinalize = useAuctionFinalize;
const useAuctionApprove = (tokenId, nftAddress) => (0, approve_hooks_1.useMCNFTApprove)(tokenId, nftAddress, "MCSingleAuction");
exports.useAuctionApprove = useAuctionApprove;
const useAddToAuction = (tokenId, royaltyFee) => {
    const commandExecutor = (0, contract_hooks_1.useDefaultContractCallback)("MCSingleAuction", "createSingleAuction");
    const translator = (0, translation_hooks_1.useComponentTranslation)("txMessages");
    const { chainId } = (0, core_1.useWeb3React)();
    return (0, react_1.useCallback)((buyNowPrice, startPrice, quantityCheck, auctionType, duration, endDate, startDate, paymentToken) => __awaiter(void 0, void 0, void 0, function* () {
        const summary = translator("createSingleAuction", {
            tokenId,
        });
        if ([buyNowPrice, startPrice].some((value) => !Boolean(value))) {
            return;
        }
        const startAuctionDate = auctionType === "limit" ? (0, object_1.getTimeStampInSec)(startDate) : (0, object_1.getTimeStampInSec)();
        const endAuctionDate = auctionType === "limit" ? (0, object_1.getTimeStampInSec)(endDate) : (0, object_1.getTimeStampInSec)() + Number(duration || 0) * 3600;
        const promiseCallback = yield commandExecutor(summary, [
            _abi_1.ContractManager.getCurrentContractAddress("MCNFT", chainId),
            tokenId,
            (0, number_1.denormalizeValue)(startPrice, paymentToken.decimals).toFixed(0),
            (0, number_1.denormalizeValue)(buyNowPrice, paymentToken.decimals).toFixed(0),
            startAuctionDate,
            endAuctionDate,
            royaltyFee,
            quantityCheck,
            paymentToken.address,
        ], tokenId);
        return promiseCallback();
    }), [tokenId, royaltyFee, commandExecutor]);
};
exports.useAddToAuction = useAddToAuction;
