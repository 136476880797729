"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActionAwaiter = void 0;
class ActionAwaiter {
    constructor() {
        this.recreate();
    }
    get resolve() {
        return this._resolve;
    }
    get reject() {
        return this._reject;
    }
    get promise() {
        return this._promise;
    }
    recreate() {
        this._promise = new Promise((resolve, reject) => {
            this._resolve = resolve;
            this._reject = reject;
        });
    }
    static execRetryPromise(action, validator, delay = 500, attempts = 10) {
        const awaiter = new ActionAwaiter();
        awaiter.retryAction(action, validator, delay, attempts);
        return awaiter;
    }
    retryAction(action, validator, delay = 500, attempts = 10) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const result = yield action();
                if (validator(result)) {
                    this.resolve(result);
                }
                else {
                    if (attempts <= 0) {
                        throw new Error("Invalid operation exception, The number of attempts has reached the maximum");
                    }
                    setTimeout(() => {
                        this.retryAction(action, validator, delay, --attempts);
                    }, delay);
                }
            }
            catch (error) {
                this.reject(error);
            }
        });
    }
}
exports.ActionAwaiter = ActionAwaiter;
