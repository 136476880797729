"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BlockTrackerService = void 0;
const config_1 = require("@app/config");
const _dictionaries_1 = require("@dictionaries");
const local_storage_1 = require("@utils/local-storage");
const http_service_1 = require("./http.service");
class BlockTrackerService extends http_service_1.HttpService {
    constructor() {
        super(config_1.CONFIG.baseApiUrl);
        this.addOperation = (block, tokenId, operation) => {
            local_storage_1.LocalStorage.updateValue("BLOCK_TRACKER", { [tokenId]: { block, operation } });
        };
        this.getList = () => local_storage_1.LocalStorage.getValue("BLOCK_TRACKER");
        this.removeOperationsByBlock = (block) => {
            const operations = local_storage_1.LocalStorage.getValue("BLOCK_TRACKER");
            if (!operations) {
                return { notCompleted: {}, completed: {} };
            }
            const [notCompleted, completed] = Object.entries(operations).reduce(([notCompleted, completed], [key, opData]) => {
                if (block >= opData.block) {
                    completed[key] = opData;
                }
                else {
                    notCompleted[key] = opData;
                }
                return [notCompleted, completed];
            }, [{}, {}]);
            local_storage_1.LocalStorage.remove("BLOCK_TRACKER");
            local_storage_1.LocalStorage.updateValue("BLOCK_TRACKER", notCompleted);
            return { notCompleted, completed };
        };
        this.getLatestSyncedBlock = () => __awaiter(this, void 0, void 0, function* () {
            const { object: { blockNumber }, } = yield this.httpClient.get(_dictionaries_1.API_ROUTES.getLatestSyncedBlockUrl());
            return blockNumber;
        });
    }
}
exports.BlockTrackerService = BlockTrackerService;
