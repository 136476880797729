"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAddToMarket = exports.useMarketApprove = exports.useCancelSale = exports.useBulkBuyNFT = exports.useBuyingNFT = void 0;
const _abi_1 = require("@abi");
const voucher_service_1 = require("@app/services/voucher.service");
const approve_hooks_1 = require("@hooks/contracts/approve.hooks");
const contract_hooks_1 = require("@hooks/contracts/contract.hooks");
const translation_hooks_1 = require("@hooks/translation.hooks");
const object_1 = require("@utils/object");
const number_1 = require("@utils/object/number");
const core_1 = require("@web3-react/core");
const react_1 = require("react");
const voucherService = new voucher_service_1.VoucherService();
const useBuyingNFT = (tokenId, address) => {
    const { account, chainId } = (0, core_1.useWeb3React)();
    const translator = (0, translation_hooks_1.useComponentTranslation)("txMessages");
    const commandExecutor = (0, contract_hooks_1.useDefaultContractWithAddressCallback)("MCSingleMarket", address, "buy");
    const summary = translator("buyMarketPositions", {
        tokenId,
    });
    return (0, react_1.useCallback)(() => __awaiter(void 0, void 0, void 0, function* () {
        if (!account) {
            return;
        }
        const tokenVoucher = yield voucherService.getBuyVoucher(account, tokenId);
        const promiseCallback = yield commandExecutor(summary, [_abi_1.ContractManager.getCurrentContractAddress("MCNFT", chainId), tokenId, tokenVoucher], tokenId);
        return promiseCallback();
    }), [commandExecutor, tokenId, account, address]);
};
exports.useBuyingNFT = useBuyingNFT;
const useBulkBuyNFT = (address) => {
    const { account, chainId } = (0, core_1.useWeb3React)();
    const translator = (0, translation_hooks_1.useComponentTranslation)("txMessages");
    const commandExecutor = (0, contract_hooks_1.useDefaultContractWithAddressCallback)("MCSingleMarket", address, "bulkBuyOf");
    return (0, react_1.useCallback)((tokenIds) => __awaiter(void 0, void 0, void 0, function* () {
        if (!account) {
            return;
        }
        const summary = translator("bulkBuyMarketPositions", {
            amount: tokenIds.length,
        });
        const tokenVoucher = yield voucherService.getBulkBuyVoucher(account, tokenIds[0]);
        const promiseCallback = yield commandExecutor(summary, [_abi_1.ContractManager.getCurrentContractAddress("MCNFT", chainId), tokenIds, tokenVoucher], tokenIds.join());
        return promiseCallback();
    }), [commandExecutor, account, address]);
};
exports.useBulkBuyNFT = useBulkBuyNFT;
const useCancelSale = (tokenId, address) => {
    const { chainId } = (0, core_1.useWeb3React)();
    const commandExecutor = (0, contract_hooks_1.useDefaultContractWithAddressCallback)("MCSingleMarket", address, "cancelSale");
    const translator = (0, translation_hooks_1.useComponentTranslation)("txMessages");
    const summary = translator("cancelSale", {
        tokenId,
    });
    return (0, react_1.useCallback)(() => __awaiter(void 0, void 0, void 0, function* () {
        const promiseCallback = yield commandExecutor(summary, [_abi_1.ContractManager.getCurrentContractAddress("MCNFT", chainId), tokenId], tokenId);
        return promiseCallback();
    }), [commandExecutor, tokenId, address]);
};
exports.useCancelSale = useCancelSale;
const useMarketApprove = (tokenId, nftAddress) => (0, approve_hooks_1.useMCNFTApprove)(tokenId, nftAddress, "MCSingleMarket");
exports.useMarketApprove = useMarketApprove;
const useAddToMarket = (tokenId, royaltyFee) => {
    const commandExecutor = (0, contract_hooks_1.useDefaultContractCallback)("MCSingleMarket", "sellItem");
    const translator = (0, translation_hooks_1.useComponentTranslation)("txMessages");
    const { account, chainId } = (0, core_1.useWeb3React)();
    const summary = translator("sellItem", {
        tokenId,
    });
    return (0, react_1.useCallback)((fixedPrice, paymentToken) => __awaiter(void 0, void 0, void 0, function* () {
        const promiseCallback = yield commandExecutor(summary, [
            _abi_1.ContractManager.getCurrentContractAddress("MCNFT", chainId),
            tokenId,
            (0, number_1.denormalizeValue)(fixedPrice, paymentToken.decimals).toFixed(0),
            (0, number_1.denormalizeValue)(royaltyFee, 0).toFixed(0),
            (0, object_1.getTimeStampInSec)(),
            paymentToken.address,
        ], tokenId);
        return promiseCallback();
    }), [account, commandExecutor]);
};
exports.useAddToMarket = useAddToMarket;
