"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.API_ROUTES = void 0;
exports.API_ROUTES = {
    getNonceURL: (account, query) => `/v4/user/auth/getNonce/${account}${query}`,
    getJwtUrl: () => "/v4/user/auth",
    getTokenUrl: (tokenId) => `/v4/tokens/${tokenId}`,
    getTokenHistoryUrl: (tokenId, query) => `/v4/tokens/${tokenId}/history${query}`,
    createTokenUrl: () => "/v4/tokens",
    getTokensUrl: (query) => `/v4/tokens${query}`,
    getUserStatsByAccountUrl: (account) => `/v4/users/${account}/stats`,
    getUserByAccountUrl: (account) => `/v4/users/${account}`,
    getUsersUrl: (query) => `/v4/users${query}`,
    updateUserUrl: (account) => `/v4/users/${account}`,
    getUserRefStatsUrl: (account) => `/v4/users/${account}/stats`,
    requestCreatorUrl: (account) => `/v4/creators/${account}`,
    rejectCreatorUrl: (account) => `/v4/creators/${account}`,
    getCreatorsUrl: (query) => `/v4/creators${query}`,
    getLatestSyncedBlockUrl: () => `/v4/processing/meta`,
    getCollections: (query) => `/v4/collections${query}`,
    getCollectionById: (id) => `/v4/collections/${id}`,
    getCollectionStats: (id) => `/v4/collections/${id}/stats`,
    getCollectionNames: () => `/v4/collections/names`,
    createCollection: () => `/v4/collections/`,
    updateCollection: (id) => `/v4/collections/${id}`,
    getUserRating: (query) => `/v4/user/rating${query}`,
    getCollectionRating: (query) => `/v4/collection/rating${query}`,
    getRefRating: (query) => `/v4/user/ref/rating${query}`,
    requestLotteryWinnerVoucher: () => `/v4/voucher/winner`,
    requestLotteryTicketsVoucher: () => `/v4/lottery/tickets`,
    requestAuctionBidVoucher: () => `/v4/voucher/bid`,
    requestAuctionBuyNowVoucher: () => `/v4/voucher/buyNow`,
    requestAuctionFinalizeVoucher: () => `/v4/voucher/finalizeAuction`,
    requestMarketBuyVoucher: () => `/v4/voucher/buy`,
    requestMarketBulkBuyVoucher: () => `/v4/voucher/bulkBuy`,
};
