"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MasterPage = void 0;
const React = __importStar(require("react"));
const header_1 = require("@components/header");
const notifications_1 = require("@components/notifications");
const footer_1 = require("@components/footer");
const public_routes_1 = require("@shared-modules/components/public-routes");
const routes_1 = require("../../routes");
const translation_hooks_1 = require("@hooks/translation.hooks");
require("./styles.scss");
const react_router_dom_1 = require("react-router-dom");
const identity_reducer_1 = require("@app/store/reducers/identity.reducer");
const burger_1 = require("@shared-modules/components/burger");
const logo_1 = require("@shared-modules/components/logo");
const application_state_reducer_1 = require("@app/store/reducers/application-state.reducer");
const tx_error_popup_1 = require("@components/tx-error-popup");
const cart_popup_1 = require("@components/cart-popup");
const cart_reducer_1 = require("@app/store/reducers/cart.reducer");
const MasterPage = (props) => {
    const isMinter = (0, identity_reducer_1.useInRole)(identity_reducer_1.AppRole.minter);
    const toggleMenu = (0, application_state_reducer_1.useLeftHandNavToggle)();
    const leftHandNavState = (0, application_state_reducer_1.useLeftHandNavState)();
    const togglePopupVisibility = (0, cart_reducer_1.useCartToggler)();
    const translator = (0, translation_hooks_1.usePagesTranslation)("masterPage");
    const isPopupVisible = (0, cart_reducer_1.useCartPopupVisibility)();
    return (React.createElement("div", { className: "p-master-page" },
        React.createElement("div", { className: "p-master-page__header-container" },
            React.createElement("div", { className: "p-master-page__header" },
                React.createElement(header_1.Header, null))),
        React.createElement("div", { className: "p-master-page__notification", id: "notification-container" }),
        React.createElement("div", { className: "p-master-page__page-content" },
            React.createElement(public_routes_1.PublicRoutes, { routes: routes_1.GLOBAL_ROUTES_V1 })),
        React.createElement("div", { className: "p-master-page__footer-container" },
            React.createElement("div", { className: "p-master-page__footer" },
                React.createElement(footer_1.Footer, null))),
        React.createElement("div", { onClick: () => toggleMenu(false), className: `p-master-page__left-hand-nav p-master-page__left-hand-nav--${leftHandNavState ? "active" : ""}` },
            React.createElement("div", { className: "p-master-page__left-hand-nav-links" },
                React.createElement("div", { className: "p-master-page__left-hand-nav-header" },
                    React.createElement(burger_1.Burger, { currentState: true }),
                    React.createElement(logo_1.Logo, null)),
                React.createElement(react_router_dom_1.Link, { className: "c-header__nav-item g-link-alternative", to: "/explore/lotteries" }, translator("lotteryLinkLabel")),
                !isMinter && (React.createElement(react_router_dom_1.Link, { className: "c-header__nav-item g-link-alternative", to: "/profile/become-creator" }, translator("becomeCreatorLinkLabel"))),
                React.createElement(react_router_dom_1.Link, { className: "c-header__nav-item g-link-alternative", to: "/rating" }, translator("ratingLinkLabel")),
                React.createElement(react_router_dom_1.Link, { className: "c-header__nav-item g-link", to: "/explore" }, translator("explorerLinkLabel")),
                React.createElement("a", { className: "c-header__nav-item g-link-alternative", onClick: togglePopupVisibility }, translator("cartLinkLabel")))),
        React.createElement(notifications_1.Notifications, null),
        React.createElement(tx_error_popup_1.TxErrorPopup, null),
        isPopupVisible && React.createElement(cart_popup_1.CartPopup, null)));
};
exports.MasterPage = MasterPage;
