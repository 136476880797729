"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokenService = void 0;
const config_1 = require("@app/config");
const _dictionaries_1 = require("@dictionaries");
const http_1 = require("@utils/http");
const http_service_1 = require("./http.service");
class TokenService extends http_service_1.HttpService {
    constructor() {
        super(config_1.CONFIG.baseApiUrl);
        this.getTokenById = (id) => __awaiter(this, void 0, void 0, function* () {
            const { object } = yield this.httpClient.get(_dictionaries_1.API_ROUTES.getTokenUrl(id));
            return object;
        });
        this.createToken = (token, jwt) => __awaiter(this, void 0, void 0, function* () {
            const headers = this.attachJwt(jwt);
            yield this.httpClient.post(_dictionaries_1.API_ROUTES.createTokenUrl(), {
                body: JSON.stringify(token),
                headers,
            });
        });
        this.getTokenListByFilter = (filter, page = 1, limit = 20) => __awaiter(this, void 0, void 0, function* () {
            const query = (0, http_1.parseToUrlQuery)(Object.assign(Object.assign({}, filter), { page, limit }), true);
            const { object } = yield this.httpClient.get(_dictionaries_1.API_ROUTES.getTokensUrl(query));
            return object;
        });
        this.getTokenHistory = (tokenId, filters, page = 1, limit = 20) => __awaiter(this, void 0, void 0, function* () {
            const query = (0, http_1.parseToUrlQuery)(Object.assign({ page, limit }, filters), true);
            const { object } = yield this.httpClient.get(_dictionaries_1.API_ROUTES.getTokenHistoryUrl(tokenId, query));
            return object;
        });
    }
}
exports.TokenService = TokenService;
