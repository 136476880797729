"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Logo = void 0;
const React = __importStar(require("react"));
const Logo = (props) => {
    const { scale, absolute: [width, height] = [] } = props;
    const baseWidth = 161;
    const baseHeight = 44;
    const newWidth = scale !== undefined ? scale * baseWidth : width || baseWidth;
    const newHeight = scale !== undefined ? scale * baseHeight : height || baseHeight;
    return (React.createElement("svg", { width: newWidth, height: newHeight, viewBox: "0 0 1032 286", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { clipPath: "url(#clip0_4001_127067)" },
            React.createElement("path", { d: "M253.428 86.8498V199.175C253.428 203.248 250.089 206.455 245.96 206.455H87.5647C85.8955 206.455 84.4018 205.935 83.172 204.982C85.4561 206.455 88.4428 206.628 90.9908 205.242L189.559 149.079C191.844 147.779 193.162 145.439 193.249 143.012C193.162 140.586 191.844 138.245 189.559 136.945L90.9031 80.6962C88.2673 79.2227 85.1044 79.4827 82.8203 81.1298C84.1385 80.0898 85.7193 79.4827 87.5647 79.4827H246.048C250.089 79.4827 253.428 82.7762 253.428 86.8498Z", fill: "#DF3E3E" }),
            React.createElement("path", { d: "M193.253 143.013C193.165 140.587 191.847 138.247 189.563 136.947L90.9061 80.6968C88.271 79.2239 85.1081 79.4839 82.824 81.1304L82.7362 81.2168C81.1548 82.5168 80.1005 84.5975 80.1005 86.7639V199.089C80.1005 201.343 81.1548 203.423 82.824 204.723C82.0336 204.116 81.3309 203.423 80.8038 202.469L45.8387 143.013L1.47387 67.1762C-0.546715 63.623 0.595336 59.2028 4.19725 57.1226L102.854 0.96018C106.456 -1.03324 110.936 0.180146 113.045 3.64697L157.41 79.4839L192.287 139.027C192.989 140.327 193.253 141.627 193.253 143.013Z", fill: "#FF6363" }),
            React.createElement("path", { d: "M193.251 143.014C193.163 145.441 191.845 147.781 189.561 149.081L90.9041 205.244C88.3567 206.63 85.37 206.457 83.0859 204.984C82.9975 204.897 82.9097 204.81 82.7342 204.724C81.065 203.424 80.0107 201.344 80.0107 199.09V86.8515C80.0107 84.5986 80.9773 82.6051 82.6465 81.3051L82.7342 81.218C85.0183 79.5715 88.1812 79.3115 90.8163 80.7851L189.561 136.948C191.845 138.248 193.163 140.588 193.251 143.014Z", fill: "#F2F2F2" }),
            React.createElement("path", { d: "M193.25 143.012C193.338 144.312 192.987 145.699 192.284 146.912L157.407 206.455L113.042 282.292C111.021 285.845 106.453 286.972 102.851 284.979L4.19432 228.816C0.592405 226.823 -0.549644 222.315 1.47095 218.762L45.8358 143.012L80.7126 83.4696C81.2398 82.6025 81.9424 81.8225 82.6457 81.3025C81.0643 82.6025 80.01 84.6831 80.01 86.8496V199.175C80.01 201.428 81.0643 203.508 82.7335 204.808C82.8212 204.895 82.909 204.982 83.0845 205.068C85.3686 206.542 88.356 206.715 90.9033 205.328L189.56 149.079C191.844 147.779 193.162 145.439 193.25 143.012Z", fill: "#FB4646" }),
            React.createElement("path", { d: "M407.5 49.5H386.129L360.75 91.0405L335.371 49.5H314V143H335.371V88.6363L359.548 128.307H361.952L386.129 88.6363V143H407.5V49.5Z", fill: "black" }),
            React.createElement("path", { d: "M492.922 113.161H440.793C441.546 117.758 443.547 121.423 446.796 124.157C450.085 126.852 454.266 128.199 459.338 128.199C465.401 128.199 470.612 126.079 474.971 121.839L488.643 128.259C485.235 133.093 481.153 136.679 476.398 139.017C471.643 141.316 465.996 142.465 459.457 142.465C449.313 142.465 441.05 139.275 434.671 132.895C428.291 126.476 425.101 118.451 425.101 108.822C425.101 98.9545 428.271 90.7716 434.611 84.2727C440.991 77.7343 448.976 74.465 458.566 74.465C468.75 74.465 477.032 77.7343 483.412 84.2727C489.792 90.7716 492.982 99.3706 492.982 110.07L492.922 113.161ZM476.636 100.381C475.566 96.7751 473.446 93.8427 470.275 91.5839C467.145 89.3252 463.499 88.1958 459.338 88.1958C454.821 88.1958 450.858 89.4639 447.45 92C445.31 93.5851 443.329 96.3788 441.506 100.381H476.636Z", fill: "black" }),
            React.createElement("path", { d: "M553.908 51.1643H570.076V140.801H553.908V133.965C550.738 136.977 547.548 139.156 544.338 140.503C541.168 141.811 537.721 142.465 533.996 142.465C525.634 142.465 518.402 139.235 512.3 132.776C506.197 126.277 503.146 118.213 503.146 108.584C503.146 98.5979 506.098 90.4149 512.003 84.035C517.907 77.655 525.08 74.465 533.52 74.465C537.404 74.465 541.049 75.1981 544.457 76.6643C547.865 78.1305 551.015 80.3298 553.908 83.2622V51.1643ZM536.849 89.4441C531.816 89.4441 527.636 91.2273 524.307 94.7937C520.978 98.3205 519.314 102.858 519.314 108.406C519.314 113.993 520.998 118.59 524.366 122.196C527.774 125.802 531.955 127.605 536.908 127.605C542.02 127.605 546.26 125.841 549.629 122.315C552.997 118.748 554.681 114.092 554.681 108.346C554.681 102.719 552.997 98.162 549.629 94.6748C546.26 91.1876 542 89.4441 536.849 89.4441Z", fill: "black" }),
            React.createElement("path", { d: "M592.842 49.5C595.695 49.5 598.132 50.5303 600.153 52.5909C602.214 54.6515 603.244 57.148 603.244 60.0804C603.244 62.9732 602.233 65.4499 600.212 67.5105C598.191 69.5315 595.774 70.542 592.961 70.542C590.068 70.542 587.591 69.5117 585.531 67.451C583.51 65.3508 582.499 62.8147 582.499 59.8427C582.499 56.9895 583.51 54.5524 585.531 52.5315C587.552 50.5105 589.989 49.5 592.842 49.5ZM584.698 76.1294H600.985V140.801H584.698V76.1294Z", fill: "black" }),
            React.createElement("path", { d: "M663.517 76.1294H679.684V140.801H663.517V133.965C660.346 136.977 657.157 139.156 653.947 140.503C650.777 141.811 647.329 142.465 643.604 142.465C635.243 142.465 628.011 139.235 621.908 132.776C615.806 126.277 612.754 118.213 612.754 108.584C612.754 98.5979 615.707 90.4149 621.611 84.035C627.515 77.655 634.688 74.465 643.129 74.465C647.012 74.465 650.658 75.1981 654.066 76.6643C657.474 78.1305 660.624 80.3298 663.517 83.2622V76.1294ZM646.457 89.4441C641.425 89.4441 637.244 91.2273 633.915 94.7937C630.587 98.3205 628.922 102.858 628.922 108.406C628.922 113.993 630.606 118.59 633.975 122.196C637.383 125.802 641.563 127.605 646.517 127.605C651.629 127.605 655.869 125.841 659.237 122.315C662.605 118.748 664.289 114.092 664.289 108.346C664.289 102.719 662.605 98.162 659.237 94.6748C655.869 91.1876 651.609 89.4441 646.457 89.4441Z", fill: "black" }),
            React.createElement("path", { d: "M778.237 68.7587L766.587 79.8741C758.661 71.5128 749.745 67.3322 739.838 67.3322C731.477 67.3322 724.423 70.1853 718.677 75.8916C712.971 81.5979 710.118 88.6317 710.118 96.993C710.118 102.818 711.386 107.99 713.922 112.507C716.458 117.024 720.045 120.571 724.681 123.147C729.317 125.723 734.469 127.01 740.136 127.01C744.97 127.01 749.388 126.119 753.391 124.336C757.393 122.513 761.792 119.224 766.587 114.469L777.88 126.238C771.421 132.538 765.319 136.917 759.573 139.374C753.827 141.791 747.268 143 739.898 143C726.306 143 715.17 138.7 706.492 130.101C697.853 121.463 693.534 110.407 693.534 96.9336C693.534 88.2156 695.496 80.4685 699.419 73.6923C703.381 66.9161 709.028 61.4674 716.359 57.3462C723.73 53.2249 731.655 51.1643 740.136 51.1643C747.348 51.1643 754.282 52.69 760.94 55.7413C767.637 58.7925 773.402 63.1317 778.237 68.7587Z", fill: "black" }),
            React.createElement("path", { d: "M791.136 51.1643H807.244V82.6678C810.414 79.9336 813.604 77.8928 816.814 76.5455C820.024 75.1585 823.273 74.465 826.562 74.465C832.982 74.465 838.391 76.6841 842.789 81.1224C846.554 84.9662 848.436 90.6131 848.436 98.0629V140.801H832.447V112.448C832.447 104.958 832.09 99.8858 831.377 97.2308C830.663 94.5758 829.435 92.5944 827.691 91.2867C825.987 89.979 823.867 89.3252 821.331 89.3252C818.042 89.3252 815.209 90.4149 812.831 92.5944C810.493 94.7739 808.869 97.7459 807.957 101.51C807.482 103.452 807.244 107.89 807.244 114.825V140.801H791.136V51.1643Z", fill: "black" }),
            React.createElement("path", { d: "M911.978 76.1294H928.146V140.801H911.978V133.965C908.808 136.977 905.618 139.156 902.408 140.503C899.238 141.811 895.791 142.465 892.066 142.465C883.704 142.465 876.472 139.235 870.37 132.776C864.267 126.277 861.216 118.213 861.216 108.584C861.216 98.5979 864.168 90.4149 870.073 84.035C875.977 77.655 883.149 74.465 891.59 74.465C895.474 74.465 899.119 75.1981 902.527 76.6643C905.935 78.1305 909.085 80.3298 911.978 83.2622V76.1294ZM894.919 89.4441C889.886 89.4441 885.705 91.2273 882.377 94.7937C879.048 98.3205 877.384 102.858 877.384 108.406C877.384 113.993 879.068 118.59 882.436 122.196C885.844 125.802 890.025 127.605 894.978 127.605C900.09 127.605 904.33 125.841 907.698 122.315C911.067 118.748 912.751 114.092 912.751 108.346C912.751 102.719 911.067 98.162 907.698 94.6748C904.33 91.1876 900.07 89.4441 894.919 89.4441Z", fill: "black" }),
            React.createElement("path", { d: "M950.912 49.5C953.765 49.5 956.202 50.5303 958.223 52.5909C960.284 54.6515 961.314 57.148 961.314 60.0804C961.314 62.9732 960.303 65.4499 958.282 67.5105C956.261 69.5315 953.844 70.542 951.031 70.542C948.138 70.542 945.661 69.5117 943.601 67.451C941.58 65.3508 940.569 62.8147 940.569 59.8427C940.569 56.9895 941.58 54.5524 943.601 52.5315C945.622 50.5105 948.059 49.5 950.912 49.5ZM942.768 76.1294H959.055V140.801H942.768V76.1294Z", fill: "black" }),
            React.createElement("path", { d: "M973.796 76.1294H989.964V82.7273C993.649 79.6364 996.978 77.4965 999.95 76.3077C1002.96 75.0793 1006.03 74.465 1009.16 74.465C1015.58 74.465 1021.03 76.704 1025.51 81.1818C1029.27 84.986 1031.16 90.6131 1031.16 98.0629V140.801H1015.11V112.448C1015.11 104.72 1014.75 99.5886 1014.04 97.0524C1013.36 94.5163 1012.16 92.5944 1010.41 91.2867C1008.71 89.9394 1006.59 89.2657 1004.05 89.2657C1000.76 89.2657 997.929 90.3753 995.552 92.5944C993.214 94.7739 991.589 97.8054 990.677 101.689C990.202 103.71 989.964 108.089 989.964 114.825V140.801H973.796V76.1294Z", fill: "black" }),
            React.createElement("path", { d: "M349.515 184.773V229.5H343.312L320.577 196.697H320.162V229.5H313.414V184.773H319.66L342.417 217.619H342.832V184.773H349.515Z", fill: "#2BB596" }),
            React.createElement("path", { d: "M368.584 229.5V184.773H396.32V190.582H375.332V204.21H394.333V209.997H375.332V229.5H368.584Z", fill: "#2BB596" }),
            React.createElement("path", { d: "M411.638 190.582V184.773H446.254V190.582H432.298V229.5H425.572V190.582H411.638Z", fill: "#2BB596" }),
            React.createElement("path", { d: "M488.629 184.773H496.819L511.058 219.541H511.582L525.822 184.773H534.011V229.5H527.591V197.134H527.176L513.985 229.434H508.656L495.465 197.112H495.05V229.5H488.629V184.773Z", fill: "#2BB596" }),
            React.createElement("path", { d: "M556.826 229.5H549.663L565.758 184.773H573.555L589.651 229.5H582.487L569.842 192.897H569.493L556.826 229.5ZM558.027 211.985H581.264V217.663H558.027V211.985Z", fill: "#2BB596" }),
            React.createElement("path", { d: "M605.335 229.5V184.773H621.278C624.743 184.773 627.618 185.37 629.904 186.563C632.205 187.757 633.923 189.41 635.058 191.521C636.194 193.618 636.762 196.042 636.762 198.794C636.762 201.531 636.187 203.94 635.037 206.022C633.901 208.09 632.183 209.699 629.882 210.849C627.597 211.999 624.721 212.574 621.256 212.574H609.179V206.765H620.644C622.828 206.765 624.605 206.452 625.973 205.826C627.356 205.2 628.368 204.29 629.009 203.096C629.65 201.902 629.97 200.468 629.97 198.794C629.97 197.105 629.642 195.641 628.987 194.404C628.346 193.166 627.335 192.22 625.951 191.565C624.583 190.895 622.785 190.56 620.557 190.56H612.083V229.5H605.335ZM627.415 209.32L638.465 229.5H630.778L619.945 209.32H627.415Z", fill: "#2BB596" }),
            React.createElement("path", { d: "M654.199 229.5V184.773H660.947V206.132H661.493L680.253 184.773H688.749L670.709 204.909L688.814 229.5H680.69L666.232 209.517L660.947 215.588V229.5H654.199Z", fill: "#2BB596" }),
            React.createElement("path", { d: "M704.263 229.5V184.773H732.305V190.582H711.012V204.21H730.842V209.997H711.012V223.691H732.567V229.5H704.263Z", fill: "#2BB596" }),
            React.createElement("path", { d: "M748.339 190.582V184.773H782.954V190.582H768.999V229.5H762.272V190.582H748.339Z", fill: "#2BB596" }),
            React.createElement("path", { d: "M799.829 229.5V184.773H815.771C819.251 184.773 822.134 185.406 824.42 186.673C826.706 187.939 828.417 189.672 829.552 191.87C830.688 194.054 831.256 196.515 831.256 199.252C831.256 202.004 830.681 204.479 829.53 206.678C828.395 208.862 826.677 210.594 824.376 211.875C822.09 213.142 819.215 213.775 815.75 213.775H804.786V208.054H815.138C817.337 208.054 819.12 207.675 820.489 206.918C821.857 206.146 822.862 205.098 823.503 203.773C824.143 202.448 824.464 200.941 824.464 199.252C824.464 197.563 824.143 196.064 823.503 194.753C822.862 193.443 821.85 192.416 820.467 191.674C819.098 190.931 817.293 190.56 815.051 190.56H806.577V229.5H799.829Z", fill: "#2BB596" }),
            React.createElement("path", { d: "M848.392 229.5V184.773H855.14V223.691H875.407V229.5H848.392Z", fill: "#2BB596" }),
            React.createElement("path", { d: "M898.391 229.5H891.228L907.324 184.773H915.12L931.216 229.5H924.053L911.408 192.897H911.058L898.391 229.5ZM899.593 211.985H922.83V217.663H899.593V211.985Z", fill: "#2BB596" }),
            React.createElement("path", { d: "M981.947 199.318H975.133C974.871 197.862 974.383 196.581 973.67 195.474C972.956 194.367 972.083 193.428 971.049 192.657C970.015 191.885 968.858 191.303 967.577 190.91C966.31 190.516 964.963 190.32 963.536 190.32C960.959 190.32 958.652 190.968 956.613 192.264C954.589 193.559 952.988 195.459 951.809 197.964C950.644 200.468 950.061 203.525 950.061 207.136C950.061 210.776 950.644 213.848 951.809 216.353C952.988 218.857 954.597 220.75 956.635 222.031C958.673 223.312 960.967 223.953 963.514 223.953C964.927 223.953 966.266 223.763 967.533 223.385C968.814 222.992 969.972 222.417 971.005 221.66C972.039 220.902 972.913 219.978 973.626 218.886C974.354 217.779 974.856 216.513 975.133 215.086L981.947 215.108C981.583 217.306 980.877 219.33 979.829 221.179C978.795 223.014 977.463 224.601 975.832 225.94C974.216 227.265 972.367 228.291 970.285 229.019C968.203 229.747 965.931 230.111 963.471 230.111C959.598 230.111 956.147 229.194 953.119 227.36C950.09 225.511 947.703 222.868 945.956 219.432C944.223 215.996 943.357 211.897 943.357 207.136C943.357 202.361 944.23 198.262 945.977 194.841C947.725 191.405 950.112 188.769 953.141 186.935C956.169 185.086 959.613 184.161 963.471 184.161C965.844 184.161 968.057 184.503 970.11 185.188C972.177 185.857 974.034 186.847 975.679 188.158C977.324 189.454 978.686 191.041 979.763 192.919C980.84 194.782 981.568 196.915 981.947 199.318Z", fill: "#2BB596" }),
            React.createElement("path", { d: "M999.247 229.5V184.773H1027.29V190.582H1006V204.21H1025.83V209.997H1006V223.691H1027.55V229.5H999.247Z", fill: "#2BB596" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0_4001_127067" },
                React.createElement("rect", { width: "1032", height: "286", fill: "white" })))));
};
exports.Logo = Logo;
