"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VoucherService = void 0;
const config_1 = require("@app/config");
const _dictionaries_1 = require("@dictionaries");
const http_service_1 = require("./http.service");
class VoucherService extends http_service_1.HttpService {
    constructor() {
        super(config_1.CONFIG.baseApiUrl);
        this.getAuctionBidVoucher = (tokenId, bidder, amount) => __awaiter(this, void 0, void 0, function* () {
            const { object } = yield this.httpClient.post(_dictionaries_1.API_ROUTES.requestAuctionBidVoucher(), {
                body: JSON.stringify({ bidder, amount, tokenId }),
            });
            return object;
        });
        this.getLotteryWinnerVoucher = (tokenId) => __awaiter(this, void 0, void 0, function* () {
            const { object } = yield this.httpClient.post(_dictionaries_1.API_ROUTES.requestLotteryWinnerVoucher(), {
                body: JSON.stringify({ tokenId }),
            });
            return object;
        });
        this.getFinalizeAuctionVoucher = (account, tokenId) => __awaiter(this, void 0, void 0, function* () {
            const { object } = yield this.httpClient.post(_dictionaries_1.API_ROUTES.requestAuctionFinalizeVoucher(), {
                body: JSON.stringify({ tokenId, account }),
            });
            return object;
        });
        this.getBuyNowVoucher = (account, tokenId) => __awaiter(this, void 0, void 0, function* () {
            const { object } = yield this.httpClient.post(_dictionaries_1.API_ROUTES.requestAuctionBuyNowVoucher(), {
                body: JSON.stringify({ tokenId, account }),
            });
            return object;
        });
        this.getBuyVoucher = (account, tokenId) => __awaiter(this, void 0, void 0, function* () {
            const { object } = yield this.httpClient.post(_dictionaries_1.API_ROUTES.requestMarketBuyVoucher(), {
                body: JSON.stringify({ tokenId, account }),
            });
            return object;
        });
        this.getBulkBuyVoucher = (account, tokenId) => __awaiter(this, void 0, void 0, function* () {
            const { object } = yield this.httpClient.post(_dictionaries_1.API_ROUTES.requestMarketBulkBuyVoucher(), {
                body: JSON.stringify({ tokenId, account }),
            });
            return object;
        });
        this.getTicketsVoucher = (account, tokenId) => __awaiter(this, void 0, void 0, function* () {
            const { object } = yield this.httpClient.post(_dictionaries_1.API_ROUTES.requestLotteryTicketsVoucher(), {
                body: JSON.stringify({ tokenId, account }),
            });
            return object;
        });
    }
}
exports.VoucherService = VoucherService;
