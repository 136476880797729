"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeCancelable = void 0;
function makeCancelable(promise) {
    let hasCanceled = false;
    const wrappedPromise = new Promise((resolve, reject) => {
        promise.then((value) => (hasCanceled ? reject({ isCanceled: true }) : resolve(value)), (error) => (hasCanceled ? reject({ isCanceled: true }) : reject(error)));
    });
    return {
        promise: wrappedPromise,
        // tslint:disable-next-line:object-literal-sort-keys
        cancel: () => {
            hasCanceled = true;
        },
    };
}
exports.makeCancelable = makeCancelable;
